<template>
  <tr>
    <td style="width: 2%;">
        <div class="d-flex align-items-center">
            <i class="las font-20 mr-2" :class="icon"></i>
        </div>
    </td>
    <td style="width: 60%;">
        {{ notification.libelle }}
        <!-- <div class="row">
            <div class="col-12 flex-wrap">{{ notification.objet }}</div>
        </div> -->
    </td>
    <td>{{ notification.createdAt|showTime }}</td>
    <td>
        <span class="btn btn-sm btn-primary"> View </span>
    </td>
    <!-- {{ notification }} -->
</tr>
</template>

<script>
import {NOTIFICATION_ICON} from '../../constants/notification'
export default {
    props:  {
        notification: {type: Object, required: true}
    },
    computed: {
        icon(){
            return NOTIFICATION_ICON(this.notification.type)
        }
    }

}
</script>

<style>

</style>