<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="notifications-table-widget">
      <div class="widget-heading">
          <!-- <h5 class="">Notifications</h5>
          <div class="d-none d-md-flex switch-outer-container">
              Do not disturb
              <span class="switch">
                  <label>
                      <input type="checkbox" checked="checked" name="select">
                      <span></span>
                  </label>
              </span>
          </div> -->
      </div>
      <div class="widget-content">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                    <th><div class="th-content">Source</div></th>
                    <th><div class="th-content">Message</div></th>
                    <th><div class="th-content">Time</div></th>
                    <th><div class="th-content">Action</div></th>
                </tr>
              </thead>
              <tbody>
                <notification-tr v-for="(n, index) in sortedNotifications" :key="index"  :notification="n"/>
              </tbody>
            </table>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import Navbar from '../components/navbar.vue'
import NotificationTr from '../components/user/notificationTr.vue'
export default {
    components: {Navbar, NotificationTr},
    data() {
        return {
          navbarItems: [ { libelle: 'Notification' } ],
            pageIcon: 'la-rocketchat',
            pageTitle: 'Chat',
            pageDescription: 'Discussion instantanée', 
        }
    },
    computed: {
      ...mapGetters({
        notifications: 'notification/notifications'
      }),
      sortedNotifications(){
        return [...this.notifications].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
      }
    }
}
</script>